import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { imageUpload } from "../../services/AuthService";
import { editWinery } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import moment from "moment";
import crossIcon from "../../images/svg/cross-circle-svgrepo-com.svg";
import { getRegionRestrictions, regionRestrictions } from "../common/ResionRestriction";
import TextEditor from "../components/TextEditor";

export default function EditWinery({ show, onHide, table, data }) {
  const vendorId = localStorage.getItem("vendorId");
  const format = "DD/MM/YYYY";
  const API_DATE_FORMAT = "YYYY-MM-DD";
  let errorsObj = {
    name: "",
    address: "",
    overview: "",
    region: "",
    price: "",
    guests: "",
    category: "",
    date: "",
    amenities: "",
    photo: "",
    days: "",
    startTime: "",
    endTime: "",
    openingHours: "",
  };
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    region: "",
    overview: "",
    guests: "",
    price: "",
    photo: [],
    category: "",
    cancelledDates: [],
    amenities: [],
    startDate: "",
    endDate: "",
  });
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [errors, setErrors] = useState(errorsObj);
  const [newText, setNewText] = useState("");
  const [reference, setReference] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const imgurl = "https://api.bookwineries.com/";

  const [formDataTime, setFormDataTime] = useState({
    Sunday: { startTime: "", endTime: "", timeSlots: [] },
    Monday: { startTime: "", endTime: "", timeSlots: [] },
    Tuesday: { startTime: "", endTime: "", timeSlots: [] },
    Wednesday: { startTime: "", endTime: "", timeSlots: [] },
    Thursday: { startTime: "", endTime: "", timeSlots: [] },
    Friday: { startTime: "", endTime: "", timeSlots: [] },
    Saturday: { startTime: "", endTime: "", timeSlots: [] },
  });

  let zipCode = "";
  let city = "";
  const [dates, setDates] = useState([
    new DateObject().set({ day: null, format }),
  ]);


  const formattedDates = dates?.map((date, index) => {
    return date.format();
  });


  const handleAddText = (e) => {
    e.preventDefault();
    const newArr = reference;
    if (newText.trim() && newText != "") {
      newArr.push(newText.trim());
    }
    setReference(newArr);
    setNewText("");
  };

  const handleUpdateText = () => {
    // ...
    const updatedReference = [...reference];
    updatedReference[currentEditIndex] = newText;
    setReference(updatedReference);
    setNewText("");
    setIsEdit(false);
  };
  const handleEdit = (index, item) => {
    // Set newText state to the item being edited
    setNewText(item);
    // Set the currentEditIndex state to the index of the item being edited
    setCurrentEditIndex(index);
    // Set isEdit state to true to indicate that we're in edit mode
    setIsEdit(true);
  };
  const handleDelete = (index) => {
    const newReferences = [...reference];
    newReferences.splice(index, 1);
    setReference(newReferences);
  };

  const [uploadedImages, setUploadedImages] = useState([null, null, null, null, null]); // Already uploaded images
  const [imagesData, setImagesData] = useState([null, null, null, null, null]); // To store selected images
  const [images, setImages] = useState([null, null, null, null, null]); // To display images including selected and existing ones
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (data && data.frontCancelledDates.length > 0) {
      const convertedDates = data.frontCancelledDates.map(dateStr =>
        new DateObject({ date: dateStr, format: format })
      );
      setDates(convertedDates);
    }

    let newImageUrl = [...uploadedImages];
    if (data && data.photo && data.photo.length > 0) {
      data.photo.forEach((url, index) => {
        newImageUrl[index] = url
      })
    }

    setUploadedImages(newImageUrl);
  }, [])

  const handleDateChange = (newDates) => {
    setDates(newDates);
  };

  const handleImageChange = (e, index) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      const updatedImages = [...images];
      updatedImages[index] = imageUrl; // Update the local preview
      setImages(updatedImages);

      const updatedImagesData = [...imagesData];
      updatedImagesData[index] = selectedFile; // Store the file for upload later
      setImagesData(updatedImagesData);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages[index] = null; // Remove the image from the preview
    setImages(updatedImages);

    const updatedImagesData = [...imagesData];
    updatedImagesData[index] = null; // Remove the image data
    setImagesData(updatedImagesData);
  };

  const uploadPromise = async (selectedFile, vendorId) => {
    try {
      const response = await imageUpload(selectedFile, vendorId);
      return response.data?.data?.photo;
    } catch (error) {
      console.log("Image upload error", error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const selectedFiles = e.target.files;

    // Check if no files are selected
    if (selectedFiles.length === 0) {
      setErrors({ ...errors, photo: "No files selected" });
      return;
    }
    // Ensure the number of images doesn't exceed 5
    if (uploadedImages.length + selectedFiles.length > 5) {
      setErrors({ ...errors, photo: "You can only upload up to 5 images" });
      return;
    }
    const uploadPromises = [];
    setLoader(true);
    // Loop through each selected file and upload
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];

      if (selectedFile) {
        const uploadPromise = imageUpload(selectedFile, vendorId)
          .then((response) => {
            return response.data?.data?.photo; // Return the image URL
          })
          .catch((error) => {
            console.log(error, "image upload error");
            return null; // Return null if an error occurs
          });
        uploadPromises.push(uploadPromise);
      }
    }

    // Wait for all promises to resolve
    Promise.all(uploadPromises).then((photoUrls) => {
      const validPhotos = photoUrls.filter((url) => url !== null); // Filter out any failed uploads

      // Update the state with the new images, ensuring no more than 5
      setUploadedImages((prevImages) => {
        const updatedImages = [...prevImages, ...validPhotos];
        setLoader(false);
        if (updatedImages.length > 5) {
          setErrors({ ...errors, photo: "You can only upload 5 images" });
          return prevImages; // Return previous state if more than 5
        }

        setErrors({ ...errors, photo: "" });
        return updatedImages;
      });
    });
  };
  const handleAddress = (place) => {
    geocodeByAddress(place.label)
      .then((results) => {
        console.log(results, "address list results");

        if (results && results.length > 0) {
          const addressComponents = results[0]?.address_components || [];

          const city =
            addressComponents.find(
              (component) =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_2")
            )?.long_name || "";

          const zipCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";

          return getLatLng(results[0]);
        } else {
          throw new Error("No results found for the provided address.");
        }
      })
      .then((res) => {
        console.log(res, "lat lng res");
        setFormData({
          ...formData,
          address: place?.label,
          latitude: res.lat,
          longitude: res.lng,
        });
        setErrors({ ...errors, address: "" });
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };
  const handleAddTime = (day) => {
    const { startTime, endTime } = formDataTime[day];
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);

    if (startTime && endTime && end > start) {
      setFormDataTime((prevState) => ({
        ...prevState,
        [day]: {
          ...prevState[day],
          timeSlots: prevState[day]?.timeSlots
            ? [...prevState[day].timeSlots, { startTime, endTime }]
            : [{ startTime, endTime }],
          startTime: "",
          endTime: "",
        },
      }));
    } else {
      setErrors({
        ...errors,
        [day]: "End time must be greater than start time.",
      });
    }
  };
  const handleDeleteTime = (day, index) => {
    const updatedTimeSlots = [...formDataTime[day].timeSlots];
    updatedTimeSlots.splice(index, 1);

    setFormDataTime((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timeSlots: updatedTimeSlots,
      },
    }));
  };
  function generateTimeOptions() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  }
  const timeOptions = generateTimeOptions();
  function TimeInput({ label, value, onChange }) {
    return (
      <div className="w-100">
        <label>{label}</label>
        <input
          type="time"
          className="form-control "
          list="time-options"
          value={value}
          onChange={onChange}
        />
        <datalist id="time-options">
          {timeOptions?.map((time, index) => (
            <option key={index} value={time}></option>
          ))}
        </datalist>
      </div>
    );
  }

  async function onPostData(e) {
    e.preventDefault();
    let error = false;
    setSubmited(true);
    const errorObj = { ...errorsObj };

    // Add date validations
    if (!formData.startDate) {
      errorObj.startDate = "Start date is required";
      error = true;
    }

    if (!formData.endDate) {
      errorObj.endDate = "End date is required";
      error = true;
    }

    if (formData.startDate && formData.endDate) {
      const start = moment(formData.startDate, format);
      const end = moment(formData.endDate, format);
      
      if (end.isSameOrBefore(start)) {
        errorObj.endDate = "End date must be after start date";
        error = true;
      }
    }

    if (uploadedImages.length === 0) {
      errorObj.photo = "This field is required";
      error = true;
    }
    if (uploadedImages.length < 5) {
      errorObj.photo = "Please select 5 images";
      error = true;
    }
    if (formData?.name?.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (formData?.address?.trim() === "") {
      errorObj.address = "This field is required";
      error = true;
    }
    if (formData.guests === "") {
      errorObj.guests = "This field is required";
      error = true;
    }
    if (formData.price === "") {
      errorObj.price = "This field is required";
      error = true;
    }
    if (formData.category === "") {
      errorObj.category = "This field is required";
      error = true;
    }
    if (formData.overview.trim() === "" || formData.overview === "<p></p>") {
      errorObj.overview = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      setSubmited(false);
      return;
    }
    const uploadedImagesUrls = [...uploadedImages]; // Keep already uploaded images
    for (let i = 0; i < imagesData.length; i++) {
      const selectedFile = imagesData[i];
      if (selectedFile) {
        // Upload new image
        const imageUrl = await uploadPromise(selectedFile, vendorId);
        if (imageUrl) {
          uploadedImagesUrls[i] = imageUrl; // Add/replace new image URL
        }
      }
    }

    // Update the state with the uploaded images
    setUploadedImages(uploadedImagesUrls);
    // Final check for exactly 5 valid images
    const finalValidImages = uploadedImagesUrls.filter(url => url && url !== "null" && url !== "undefined");
    if (finalValidImages.length !== 5) {
      notifyError('Please ensure you have exactly 5 images');
      setSubmited(false);
      return;
    }


    const formDataTimeWithoutEmptyKeys = Object.keys(formDataTime).reduce(
      (acc, day) => {
        if (formDataTime[day].timeSlots.length > 0) {
          const filteredSlots = formDataTime[day].timeSlots.map((slot) => {
            const { noOfTotalGuests, availableGuests, _id, ...rest } = slot;
            return rest; // Return the remaining properties
          });

          acc.push({ day, slots: filteredSlots });
        }
        return acc;
      },
      []
    );
    try {
      // Format dates for API
      const formattedStartDate = formData.startDate ? 
        moment(formData.startDate, format).format(API_DATE_FORMAT) : 
        "";
      const formattedEndDate = formData.endDate ? 
        moment(formData.endDate, format).format(API_DATE_FORMAT) : 
        "";

      const response = await editWinery({
        ...formData,
        wineryId: data?._id,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        photo: uploadedImagesUrls,
        amenities: reference,
        cancelledDates: formattedDates,
        openingHours: formDataTimeWithoutEmptyKeys,
      });
      onHide();
      table();
      notifyTopRight(response.data?.message);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error, "error");
      setSubmited(false);
    } finally {
      //   setLoader(false);
      setSubmited(false);
    }
  }

  useEffect(() => {
    if (data) {
      setFormData({
        name: data?.name || "",
        address: data?.address || "",
        latitude: data?.loc.coordinates[1] || "",
        longitude: data?.loc.coordinates[0] || "",
        region: data?.region || "",
        overview: data?.overview || "",
        guests: data?.guests || "",
        price: data?.price || "",
        photo: data?.photo || [],
        category: data?.category || "",
        cancelledDates: data?.cancelledDates || [],
        amenities: data?.amenities || [],
        // Convert API dates to display format
        startDate: data?.startDate ? moment(data?.startDate).format(format) : "",
        endDate: data?.endDate ? moment(data?.endDate).format(format) : "",
      });

      setReference(data?.amenities || []);
      // Initialize formDataTime with data from openingHours
      const updatedFormDataTime = {};
      data?.openingHours?.forEach((dayData) => {
        updatedFormDataTime[dayData.day] = {
          startTime: "",
          endTime: "",
          timeSlots: dayData?.slots || [],
        };
      });
      // console.log(updatedFormDataTime, "updatedFormDataTime");
      setFormDataTime(updatedFormDataTime);



      // Populate initial cancelled dates from the data prop
      setInputValue(data?.address || "");
    }
  }, [data]);




  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // No need to add 1 here because Date expects zero-based months (0 = January)
    const day = today.getDate();
    return new Date(year, month, day);
  };


  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (event.target.innerText === "Select/Deselect All") {
        event.preventDefault();
      }
    };

    window.addEventListener("click", handleGlobalClick);
    return () => {
      window.removeEventListener("click", handleGlobalClick);
    };
  }, []);
  return (
    <Modal
      className="modal fade"
      show={show}
      centered
      onHide={onHide}
      size="lg"
    >
      <div className="" role="document">
        <div className="">
          <form >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Details</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">

                  <div className="field-wrapper">

                    <div className="form-group w-50" >

                      <label className="text-black font-w500">Images</label>
                      <div className="vendor-image-container  ">

                        {uploadedImages.map((image, index) => (
                          <div className="position-relative" key={index} style={{ zIndex: 0 }}>


                            <div className="vendor-image-box  " onClick={() => document.getElementById(`imageInput${index}`).click()}>
                              {
                                <div onClick={() => { handleRemoveImage(index) }} className=" remove-image-btn  " > <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.8536C2.34171 3.04882 2.65829 3.04882 2.85355 2.8536L7.5 7.5L12.1464 2.8536C12.3417 2.65834 12.6583 2.65834 12.8536 2.85355Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path><path d="M12.8536 12.1464C13.0488 11.9512 13.0488 11.6346 12.8536 11.4394L7.5 7.79297L2.85355 11.4394C2.65829 11.6346 2.34171 11.6346 2.14645 11.4394C1.95118 11.2441 1.95118 10.9275 2.14645 10.7323L6.79289 6.08587L11.4393 10.7323C11.6346 10.9275 11.6346 11.2441 11.4393 11.4394L7.5 7.79297L12.1464 12.1464C12.3417 12.3417 12.6583 12.3417 12.8536 12.1464Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>  </div>
                              }

                              {image ? (
                                <img src={images[index] ? images[index] : imgurl + image} alt={`Box ${index + 1}`} className="vendor-image-img" />
                              ) : (
                                <>
                                  {
                                    images[index] ? <img src={images[index]} alt={`Box ${index + 1}`} className="vendor-image-img" />
                                      : <p><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 1H12.5C13.3284 1 14 1.67157 14 2.5V12.5C14 13.3284 13.3284 14 12.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2.5C1 1.67157 1.67157 1 2.5 1ZM2.5 2C2.22386 2 2 2.22386 2 2.5V8.3636L3.6818 6.6818C3.76809 6.59551 3.88572 6.54797 4.00774 6.55007C4.12975 6.55216 4.24568 6.60372 4.32895 6.69293L7.87355 10.4901L10.6818 7.6818C10.8575 7.50607 11.1425 7.50607 11.3182 7.6818L13 9.3636V2.5C13 2.22386 12.7761 2 12.5 2H2.5ZM2 12.5V9.6364L3.98887 7.64753L7.5311 11.4421L8.94113 13H2.5C2.22386 13 2 12.7761 2 12.5ZM12.5 13H10.155L8.48336 11.153L11 8.6364L13 10.6364V12.5C13 12.7761 12.7761 13 12.5 13ZM6.64922 5.5C6.64922 5.03013 7.03013 4.64922 7.5 4.64922C7.96987 4.64922 8.35078 5.03013 8.35078 5.5C8.35078 5.96987 7.96987 6.35078 7.5 6.35078C7.03013 6.35078 6.64922 5.96987 6.64922 5.5ZM7.5 3.74922C6.53307 3.74922 5.74922 4.53307 5.74922 5.5C5.74922 6.46693 6.53307 7.25078 7.5 7.25078C8.46693 7.25078 9.25078 6.46693 9.25078 5.5C9.25078 4.53307 8.46693 3.74922 7.5 3.74922Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg></p>

                                  }
                                </>
                              )}
                              <input
                                type="file"
                                id={`imageInput${index}`}
                                className="vendor-image-input"
                                accept="image/*"
                                onChange={(e) => handleImageChange(e, index)}
                                style={{ display: 'none' }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="form-group w-50">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                            setErrors({ ...errors, name: "" });
                          }}
                        />
                        <span className="validation-text"></span>
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group w-50 mb-3">
                      <label className="text-black font-w500">Max amount of Guests per session</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={formData.guests}
                          min={1}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, '');
                            setFormData({
                              ...formData,
                              guests: value,
                            });
                            setErrors({ ...errors, guests: "" }); // Clear error when value changes
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="validation-text"></span>
                        {errors.guests && (
                          <div className="text-danger fs-12">
                            {errors.guests}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group w-50 mb-3">
                      <label className="text-black font-w500">Price</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={formData.price}
                          min={0}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow digits and decimal point
                            // Prevent multiple decimal points
                            if ((value.match(/\./g) || []).length <= 1) {
                              setFormData({ ...formData, price: value });
                              setErrors({ ...errors, price: "" });
                            }
                          }}
                          onKeyPress={(e) => {
                            // Allow digits and decimal point
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                            // Prevent multiple decimal points
                            if (e.key === '.' && String(formData.price).includes('.')) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="validation-text"></span>
                        {errors.price && (
                          <div className="text-danger fs-12">
                            {errors.price}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group mb-3 w-50">
                      <label className="text-black font-w500">Region</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={formData.region}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              region: e.target.value,
                            });
                            setErrors({ ...errors, region: "" });
                          }}
                        >
                          <option hidden>Select</option>
                          {regionRestrictions.map((item,index)=>(
                        <option key={index} value={item.region}>{item.region}</option>
                      ))}

                        </select>

                        <span className="validation-text"></span>
                        {errors.region && (
                          <div className="text-danger fs-12">{errors.region}</div>
                        )}
                      </div>
                    </div>
                    
                    <div className="form-group mb-3 w-50">
                      <label className="text-black font-w500">Address</label>
                      <div className="contact-name">
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          selectProps={{
                            placeholder: "Enter Address",
                            inputValue: inputValue,
                            onInputChange: (value) => setInputValue(value),
                            onChange: handleAddress,
                            noOptionsMessage: () =>
                              inputValue ? "No options" : null, // Hide "No options" when input is empty
                            className: "form-control",
                            defaultInputValue: data?.address || "",
                          }}
                          autocompletionRequest={{
                            input: inputValue,
                            componentRestrictions: { country: ["CY", "GR"] }
                          }}
                        />
                        
                        {errors.address && (
                          <div className="text-danger fs-12">
                            {errors.address}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group mb-3 w-100">
                      <label className="text-black font-w500">Start Date</label>
                      <div className="contact-name">
                        <DatePicker
                          value={formData.startDate}
                          placeholder="Select Start Date"
                          onChange={(date) => {
                            setFormData({
                              ...formData,
                              startDate: date ? date.format(format) : "",
                            });
                            setErrors({ ...errors, startDate: "" });
                            // Clear blocked dates that are outside the new range
                            if (date) {
                              const startDate = moment(date.format(format), format);
                              const endDate = formData.endDate ? moment(formData.endDate, format) : null;

                              // Filter out dates that are before the new start date
                              const newDates = dates.filter(date => {
                                const currentDate = moment(date.format(), format);
                                return currentDate.isSameOrAfter(startDate) &&
                                       (!endDate || currentDate.isSameOrBefore(endDate));
                              });
                              setDates(newDates);
                            }
                          }}
                          format={format}
                          calendarPosition="bottom-center"
                          minDate={new Date()}
                        />
                        {errors.startDate && (
                          <div className="text-danger fs-12">{errors.startDate}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 w-100">
                      <label className="text-black font-w500">End Date</label>
                      <div className="contact-name">
                        <DatePicker
                          value={formData.endDate}
                          placeholder="Select End Date"
                          onChange={(date) => {
                            setFormData({
                              ...formData,
                              endDate: date ? date.format(format) : "",
                            });
                            setErrors({ ...errors, endDate: "" });
                            // Clear blocked dates that are outside the new range
                            if (date) {
                              const startDate = formData.startDate ? moment(formData.startDate, format) : null;
                              const endDate = moment(date.format(format), format);

                              // Filter out dates that are after the new end date
                              const newDates = dates.filter(date => {
                                const currentDate = moment(date.format(), format);
                                return (!startDate || currentDate.isSameOrAfter(startDate)) &&
                                       currentDate.isSameOrBefore(endDate);
                              });
                              setDates(newDates);
                            }
                          }}
                          format={format}
                          calendarPosition="bottom-center"
                          minDate={formData.startDate ? moment(formData.startDate, format).toDate() : new Date()}
                          disabled={!formData.startDate}
                        />
                        {errors.endDate && (
                          <div className="text-danger fs-12">{errors.endDate}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group mb-3 w-100">
                      <label className="text-black font-w500">Category</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={formData.category}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              category: e.target.value,
                            });
                            setErrors({ ...errors, category: "" });
                          }}
                        >
                          <option hidden>Select</option>
                          <option>Wine Tasting</option>
                          <option>Wine Tours</option>
                          <option>Others</option>
                        </select>

                        <span className="validation-text"></span>
                        {errors.category && (
                          <div className="text-danger fs-12">
                            {errors.category}
                          </div>
                        )}
                      </div>
                    </div>
                     {/* Blocked Dates */}
                     <div className="form-group mb-3 w-100">
                        <label className="text-black font-w500">
                          Blocked Dates
                        </label>
                        <div className="contact-name">
                          <DatePicker
                            onChange={handleDateChange}
                            value={dates}
                            multiple
                            sort
                            format={format}
                            calendarPosition="bottom-center"
                            minDate={formData.startDate ? moment(formData.startDate, format).toDate() : new Date()}
                            maxDate={formData.endDate ? moment(formData.endDate, format).toDate() : undefined}
                            disabled={!formData.startDate || !formData.endDate}
                          />
                          <span className="validation-text"></span>
                      
                          {errors.date && (
                            <div className="text-danger fs-12">{errors.date}</div>
                          )}
                        </div>
                      </div>  
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">Time Slots</label>
                    <div>
                      {days?.map((day) => (
                        <div
                          key={day}
                          className="contact-name d-flex flex-column mb-3"
                          style={{ gap: "10px" }}
                        >
                          {/* Day Label */}
                          <div
                            className="d-flex flex-column flex-md-row justify-content-between align-items-center time_slot_wrapper"
                            style={{ gap: "10px" }}
                          >
                            <div className="time-slots-label edit_time_slot_label  marging_2 d-flex align-items-center ">
                              <label className="text-black">{day}</label>
                              <div className="  edit_time_slot_label_line ">
                              </div>
                            </div>
                            {/* Start Time Input */}
                            <div className=" time_slot_input ">
                              <TimeInput
                                label="Start Time"
                                value={formDataTime[day]?.startTime}
                                onChange={(e) =>
                                  setFormDataTime({
                                    ...formDataTime,
                                    [day]: {
                                      ...formDataTime[day],
                                      startTime: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>

                            {/* End Time Input */}
                            <div className="time_slot_input">
                              <TimeInput
                                label="End Time"
                                value={formDataTime[day]?.endTime}
                                onChange={(e) =>
                                  setFormDataTime({
                                    ...formDataTime,
                                    [day]: {
                                      ...formDataTime[day],
                                      endTime: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>

                            {/* Add Button */}
                            <button
                              type="button"
                              className="btn marging_2 time_slot_btn btn-primary w-md-auto"
                              onClick={() => handleAddTime(day)}
                            >
                              Add
                            </button>
                          </div>

                          {/* Display Slots Below the Inputs */}
                          {formDataTime[day]?.timeSlots?.length > 0 && (
                            <div className="w-fit">
                              {formDataTime[day]?.timeSlots?.map(
                                (slot, index) => (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary mr-2 mb-2"
                                    key={index}
                                  >
                                    {moment(slot?.startTime, "HH:mm").format(
                                      "hh:mm a"
                                    )}{" "}
                                    -{" "}
                                    {moment(slot?.endTime, "HH:mm").format(
                                      "hh:mm a"
                                    )}
                                    <img
                                      src={crossIcon}
                                      width={20}
                                      className="ml-2"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteTime(day, index);
                                      }}
                                      alt="remove"
                                    />
                                  </button>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>


                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Amenities</label>
                    {reference.length > 0 && (
                      <div className=" mb-2">
                        <ul>
                          {reference.map((item, index) => (
                            <div
                              className="d-flex justify-content-between fs-14"
                              key={index}
                            >
                              <li style={{ maxWidth: "400px" }}>{item} </li>
                              <div>
                                <i
                                  className="flaticon-381-edit-1 pointer"
                                  onClick={() => handleEdit(index, item)}
                                ></i>
                                <i
                                  className="flaticon-381-trash-1 pointer ml-2"
                                  onClick={() => handleDelete(index)}
                                ></i>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="contact-name align-items-center d-flex">
                      <input
                        type="text"
                        className="form-control mr-2"
                        value={newText}
                        onChange={(e) => {
                          setNewText(e.target.value);

                          setErrors({ ...errors, amenities: "" });
                        }}
                      />
                      <button
                        type="button"
                        className="btn nice_btn  btn-primary btn-color "
                        onClick={isEdit ? handleUpdateText : handleAddText}
                      >
                        {isEdit ? "Update" : "Add"}
                      </button>
                    </div>
                    {/* {errors.amenities && (
                      <div className="text-danger fs-12">
                        {errors.amenities}
                      </div>
                    )} */}
                  </div>

                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Overview</label>
                    <div className="contact-name">
                      <TextEditor 
                        value={formData.overview}
                        onChange={(content) => {
                          setFormData({
                            ...formData,
                            overview: content,
                          });
                          setErrors({ ...errors, overview: "" });
                        }}
                        error={errors.overview}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary fs-14 py-2 px-3"
                onClick={onPostData}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-secondary fs-14 py-2 px-3"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>

    </Modal>
  );
}
